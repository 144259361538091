import { useState } from "react";
import { IconButton, Option, Stack } from "@introist/react-foundation/v2";
import { ReactComponent as FontSizeIcon } from "./letter-text.svg";

import { FontSizePopover } from "./FontSizePopover";

export interface FontSizeSelectorProps {
  onSelected: (option: Option) => boolean | void;
}

const generateFontSizes = (start = 12, step = 2, count = 10) => {
  const fontSizes = [];
  for (let i = 0; i < count; i++) {
    fontSizes.push(`${start + i * step}px`);
  }
  return [{ key: "", title: "Default" }, ...fontSizes.map(_ => ({ key: _, title: _ }))];
};

export const FontSizeSelector = ({ onSelected }: FontSizeSelectorProps) => {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Stack ref={setPopoverRef}>
        <IconButton icon={<FontSizeIcon />} onClick={() => setOpen(!open)} size="large" />
      </Stack>

      <FontSizePopover
        options={generateFontSizes()}
        popoverRef={popoverRef}
        open={open}
        onSelected={option => {
          onSelected(option);
          setOpen(false);
        }}
        setOpen={setOpen}
      />
    </>
  );
};
