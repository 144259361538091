import { CircularLoader, Input, InputProps } from "@introist/react-foundation/v2";
import { useState } from "react";

export const InputWithSave = ({
  onSave,
  ...props
}: { onSave: (value: string) => unknown } & Omit<InputProps, "onBlur">) => {
  const [saving, setSaving] = useState(false);

  return (
    <Input
      {...props}
      onBlur={e => {
        setSaving(true);
        Promise.resolve(onSave(e.target.value)).finally(() => setSaving(false));
      }}
      endAdornment={saving ? <CircularLoader size="tiny" /> : props.endAdornment}
    />
  );
};
