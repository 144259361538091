import { ListView, TopBarRoute } from "../../components/templates";
import { api, RouterOutput } from "../../services/rpc/RpcProvider";
import { useSearch } from "../../foundation";
import { ListRow } from "../../components/organisms";
import { Title, Stack, Icon, useTheme, Drawer } from "@introist/react-foundation/v2";
import { FittedTitle } from "../../components/atoms";
import { formatDateTime } from "../../utils/DatesUtils";
import { DateFormats } from "../../utils/dates";
import { useState } from "react";
import JSONPretty from "react-json-pretty";

type Action = RouterOutput["system"]["actions"][0];

export const ActionsRoute = () => {
  const { theme } = useTheme();

  const { data: actions } = api.system.actions.useQuery({});

  const { filtered, search, setSearch } = useSearch(actions, {
    fields: ["actionIdentifier", "timestamp", "result", "error", "metadata"]
  });

  const [selectedAction, setSelectedAction] = useState<Action | undefined>();

  return (
    <TopBarRoute paths={[{ key: "actions", title: "Actions" }]}>
      <Stack vertical gap="large">
        <ListView.Header title="Actions" count={34} search={{ search, setSearch }} />
        <ListView.List
          data={filtered}
          renderRow={action => (
            <ListRow
              gridTemplateColumns="20px 130px 1fr auto"
              onClick={() => setSelectedAction(action)}
            >
              {action.success ? (
                <Icon name="checkCircle" color={theme.palette.success.default} />
              ) : (
                <Icon name="crossCircle" color={theme.palette.danger.default} />
              )}
              <Title>{formatDateTime(action.timestamp, DateFormats.datetime.long)}</Title>
              <Title variant="bold">{action.actionIdentifier}</Title>
              <FittedTitle maxLength={50} showTooltip color={theme.palette.danger.default}>
                {action.error ?? ""}
              </FittedTitle>
            </ListRow>
          )}
        />
      </Stack>
      <Drawer width="540px" open={!!selectedAction} onClose={() => setSelectedAction(undefined)}>
        {selectedAction && (
          <Stack vertical style={{ padding: "var(--spacing-large)" }}>
            <Stack vertical gap="small">
              <Title variant="bold">{selectedAction.actionIdentifier}</Title>
              <Stack>
                <Title>{formatDateTime(selectedAction.timestamp, DateFormats.datetime.long)}</Title>
                {selectedAction.success ? (
                  <Icon name="checkCircle" color={theme.palette.success.default} />
                ) : (
                  <Icon name="crossCircle" color={theme.palette.danger.default} />
                )}
              </Stack>
            </Stack>

            <Stack vertical gap="xSmall">
              <Title variant="bold">Result</Title>
              <JSONPretty json={selectedAction.result} />
            </Stack>
            <Stack vertical gap="xSmall">
              <Title variant="bold">Metadata</Title>
              <JSONPretty json={(selectedAction as any).metadata} />
            </Stack>
          </Stack>
        )}
      </Drawer>
    </TopBarRoute>
  );
};
