import { Extension } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    backgroundColor: {
      setParagraphBackgroundColor: (color: string) => ReturnType;
      unsetParagraphBackgroundColor: () => ReturnType;
    };
  }
}

export const BackgroundColor = Extension.create({
  name: "backgroundColor",

  addGlobalAttributes() {
    return [
      {
        types: ["paragraph"],
        attributes: {
          backgroundColor: {
            parseHTML: element => {
              return element.style.backgroundColor ?? null;
            },
            renderHTML: attributes => {
              return attributes.backgroundColor
                ? { style: `background-color: ${attributes.backgroundColor}` }
                : {};
            }
          }
        }
      }
    ];
  },

  addCommands() {
    return {
      setParagraphBackgroundColor:
        (backgroundColor: string) =>
        ({ commands }) => {
          return commands.updateAttributes("paragraph", { backgroundColor });
        },

      unsetParagraphBackgroundColor:
        () =>
        ({ commands }) => {
          return commands.resetAttributes("paragraph", "backgroundColor");
        }
    };
  }
});
