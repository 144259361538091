import { EventSettingProps } from "./index";
import { ToggleSettingCard } from "../../../../../components/organisms";
import { getEventSettingsMetadata } from "../util";
import { useState } from "react";
import { useUpdateCompanyEventSetting } from "../hooks";
import { Select, Stack, Title } from "@introist/react-foundation/v2";
import { api } from "../../../../../services/rpc/RpcProvider";

export const MoveToWorkday = ({ readOnly, setting }: EventSettingProps) => {
  const metadata = getEventSettingsMetadata(setting.setting);

  const { data: areaCodes } = api.holidayCalendar.areaCodes.useQuery({});

  const [active, setActive] = useState(setting.active);
  const { updateEventSetting } = useUpdateCompanyEventSetting({
    setting,
    config: { enabled: !readOnly }
  });

  return (
    <ToggleSettingCard
      autoCollapse
      readOnly={readOnly}
      key={setting.setting}
      title={metadata.title}
      description={metadata.description}
      active={active}
      onToggleActive={active => {
        setActive(active);
        updateEventSetting({ active }, () => setActive(setting.active));
      }}
    >
      <Stack vertical gap="small">
        <Stack>
          <Title>Move</Title>
          <Select
            size="small"
            element="button"
            options={[
              { key: "backward", title: "Earlier" },
              { key: "forward", title: "Later" }
            ]}
            value={(setting.conf as any).direction}
            onSelect={opt => {
              updateEventSetting({ conf: { ...setting.conf, direction: opt.key } });
            }}
            attachToRef={false}
          />
        </Stack>
        <Stack>
          <Title>Use workdays in</Title>
          <Select
            searchable
            size="small"
            element="button"
            value={(setting.conf as any).areaCode}
            options={(areaCodes ?? []).map(c => ({ key: c.code, title: c.name }))}
            attachToRef={false}
            placeholder="Select country"
            onSelect={opt => {
              updateEventSetting({ conf: { ...setting.conf, areaCode: opt.key } });
            }}
            onClear={() => {
              updateEventSetting({ conf: { ...setting.conf, areaCode: null } });
            }}
          />
        </Stack>
      </Stack>
    </ToggleSettingCard>
  );
};
