import { Icon, IconButton, Stack, Title, useTheme, useToast } from "@introist/react-foundation/v2";
import { useCallback, useMemo } from "react";
import { api } from "../../../services/rpc/RpcProvider";

export const DownloadableFile = ({ identifier }: { identifier: string }) => {
  const { theme } = useTheme();
  const toast = useToast();

  const getDownloadUrl = api.files.getDownloadUrl.useMutation();

  const [fileId, fileName] = useMemo(() => identifier.split("/"), [identifier]);

  const onDownload = useCallback(async () => {
    getDownloadUrl
      .mutateAsync({ fileId })
      .then(url => window.open(url, "blank"))
      .catch(() => toast.error("Failed to download file"));
  }, [getDownloadUrl, fileId, toast]);

  return (
    <Stack justifyContent="space-between">
      <Title variant="bold" color={theme.palette.highlight.default}>
        {fileName ?? "Unknown file"}
      </Title>
      <IconButton
        icon={<Icon name="moveTo" color={theme.palette.highlight.default} />}
        onClickWithLoading={onDownload}
      />
    </Stack>
  );
};
