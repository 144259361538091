import { Stack } from "@introist/react-foundation/v2";
import { ReactNode } from "react";
import { ContentType } from "modules/AIAssistant/schema";
import { HtmlEditor } from "../HtmlEditor/HtmlEditor";
import { AIAssistant } from "modules/AIAssistant/AIAssistant";

export type MessageEditorProps = {
  message: string;
  onChange: (message: string) => void;
  variables?: { key: string; title: string }[];
  error?: boolean;
  underline?: boolean;
  contentType?: ContentType;
  extra?: ReactNode;
};

export const MessageEditor = ({
  message,
  onChange,
  error,
  underline,
  contentType,
  extra
}: MessageEditorProps) => {
  return (
    <Stack vertical gap="xSmall">
      <HtmlEditor
        content={message}
        onChange={onChange}
        underline={underline}
        fields
        error={error}
      />
      <Stack justifyContent="space-between">
        <AIAssistant
          operation="message"
          contentType={contentType ?? ContentType.EmailMessage}
          template={message}
          onSubmit={result => {}}
        />
        {extra}
      </Stack>
    </Stack>
  );
};
