import { Option, OptionList, Popover } from "@introist/react-foundation/v2";

interface FontSizePopoverProps {
  popoverRef: HTMLElement | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSelected: (option: Option) => void;
  options: Option[];
}

export const FontSizePopover = ({
  popoverRef,
  open,
  setOpen,
  onSelected,
  options
}: FontSizePopoverProps) => (
  <Popover referenceElement={popoverRef} open={open} onClose={() => setOpen(false)}>
    <OptionList
      options={options.map(option => ({
        ...option,
        onClick: () => onSelected(option)
      }))}
    />
  </Popover>
);
