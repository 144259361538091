import {
  Button,
  Field,
  Input,
  Modal,
  Option,
  Select,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { useCallback, useState } from "react";
import { api } from "../../../../services/rpc/RpcProvider";
import { FormFooter } from "../../../datasources/components/FormFooter";

type SheetModalProps = {
  open: boolean;
  onClose: () => void;
  value: string;
  onConnect: (sheet: {
    fileId: string;
    fileTitle: string;
    sheetId: string;
    sheetTitle: string;
  }) => void;
};

type SheetOptions = {
  id: string;
  title: string;
  sheets: { id: string; title: string }[];
};

export const SheetModal = ({ open, onClose, onConnect }: SheetModalProps) => {
  const toast = useToast();

  const apiUtils = api.useContext();

  const [fileId, setFileId] = useState<string | undefined>();
  const [fileTitle, setFileTitle] = useState<string | undefined>();
  const [sheetOptions, setSheetOptions] = useState<Option[] | undefined>();
  const [sheetId, setSheetId] = useState<string | undefined>();

  const close = useCallback(() => {
    setFileId(undefined);
    setFileTitle(undefined);
    setSheetOptions(undefined);
    setSheetId(undefined);
    onClose();
  }, [onClose]);

  const onConnectClick = useCallback(async () => {
    try {
      const options: SheetOptions = await apiUtils.client.workflows.v4.steps.options.query({
        stepType: "googlesheetrow",
        params: { fileId: fileId! }
      });
      setFileTitle(options.title);
      setSheetOptions(options.sheets.map(s => ({ key: s.id.toString(), title: s.title })));
    } catch {
      toast.error("Failed to connect to Google Sheet");
    }
  }, [fileId, toast, apiUtils]);

  return (
    <Modal open={open} onClose={close} title="Connect Google Sheet" maxWidth={500}>
      <Stack vertical>
        <Title>
          Give Introist access to your sheet and copy the sheet ID to the field below. Click Connect
          to verify connection and select the Sheet you want to use.
        </Title>
        <Field title="Spreadsheet">
          <Input
            placeholder="Copy Spreadsheet ID here"
            readOnly={!!fileTitle}
            value={fileTitle ?? fileId}
            onChange={val => setFileId(val)}
            endAdornment={
              !fileTitle && (
                <Button variant="blended" onClickWithLoading={onConnectClick}>
                  Connect
                </Button>
              )
            }
          />
        </Field>
        <Field title="Sheet">
          {!sheetOptions && <Title>Connect Spreadsheet to continue</Title>}
          {sheetOptions && (
            <Select
              options={sheetOptions}
              placeholder="Select sheet"
              value={sheetId}
              onSelect={opt => setSheetId(opt.key)}
              style={{ width: "100%" }}
            />
          )}
        </Field>
        <FormFooter
          onCancel={close}
          submitDisabled={!fileId || !sheetId}
          submit="Save"
          onSubmit={async () => {
            onConnect({
              fileId: fileId!,
              fileTitle: fileTitle!,
              sheetId: sheetId!,
              sheetTitle:
                (sheetOptions ?? []).find(o => o.key === sheetId)?.title ?? "Unknown sheet"
            });
            close();
          }}
        />
      </Stack>
    </Modal>
  );
};
