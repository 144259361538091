import { CommandProps, Extension } from "@tiptap/core";

export const Emoji = Extension.create({
  name: "emoji",
  //@ts-ignore
  addCommands() {
    return {
      addEmoji:
        (emoji: string) =>
        ({ chain }: CommandProps) => {
          return chain().insertContent(emoji).run();
        }
    };
  }
});
