import { Icon, OptionList, useTheme } from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { compact } from "lodash";
import { SelectorIcon } from "../fields/EmployeeFieldSelector";
import { EmployeeRole, useRoles } from "./useRoles";
import { EmployeeFieldSelectList } from "../fields/EmployeeFieldSelectList";

export const RoleSelectorList = ({
  onSelect,
  onBack,
  fieldTypes
}: {
  onSelect: (path: string, title: string) => void;
  onBack: () => void;
  fieldTypes?: string[];
}) => {
  const { theme } = useTheme();

  const { roles } = useRoles();

  const [selectedRole, setSelectedRole] = useState<EmployeeRole | undefined>();

  const options = useMemo(() => {
    if (!selectedRole) {
      return (roles ?? []).map(r => ({
        key: r.id,
        title: r.name,
        startAdornment: <SelectorIcon icon={r.icon} />,
        onClick: () => {
          if (r.type === "email") onSelect(`roles.${r.id}`, r.name);
          if (r.type === "employee") setSelectedRole(r);
        }
      }));
    }

    return [];
  }, [roles, onSelect, selectedRole]);

  if (selectedRole) {
    return (
      <EmployeeFieldSelectList
        onSelect={(path, title) =>
          onSelect(`roles.${selectedRole.id}.${path}`, `${selectedRole.name} - ${title}`)
        }
        onBack={() => setSelectedRole(undefined)}
        title={selectedRole?.name}
        disableLinkedEmployee
        fieldTypes={fieldTypes}
      />
    );
  }

  return (
    <OptionList
      actions={compact([
        {
          key: "back",
          title: "Roles",
          startAdornment: <Icon name="arrowLeft" color={theme.palette.primary.default} />,
          onClick: onBack
        }
      ])}
      searchable
      options={options}
      clearSearchOnOptionsChange
    />
  );
};
