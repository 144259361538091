import { CircularLoader, Drawer, Field, Input, Stack } from "@introist/react-foundation/v2";
import { EmployeeRole, EmployeeRoleTypes, useRole, useRoleActions } from "./useRoles";
import { DrawerHeader } from "../../../foundation/templates/Drawer/DrawerHeader";
import { useState } from "react";
import { EmployeeSelect } from "../components/EmployeeSelect";
import { InputWithSave } from "../../../foundation/templates/InputWithSave";

export const RoleEditorDrawer = ({ id, onClose }: { id?: string; onClose: () => void }) => {
  return (
    <Drawer width="540px" open={!!id} onClose={onClose}>
      {id && <RoleEditor id={id} onClose={onClose} />}
    </Drawer>
  );
};

export const RoleEditor = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const { role } = useRole(id);

  const { update } = useRoleActions();

  if (!role) return <CircularLoader fillParent />;

  return (
    <Stack vertical style={{ padding: "var(--spacing-xLarge)" }}>
      <DrawerHeader title={role.name} onClose={onClose} />
      <Field title="Role type">
        <Input
          value={EmployeeRoleTypes.find(t => t.key === role.type)?.title ?? "Unknown role type"}
          disabled
        />
      </Field>
      <Field title="Current value">
        <RoleValueEditor role={role} onChange={value => update(role.id, { value })} />
      </Field>
    </Stack>
  );
};

export const RoleValueEditor = ({
  role,
  onChange
}: {
  role: EmployeeRole;
  onChange?: (value: string) => unknown;
}) => {
  const [internalValue, setInternalValue] = useState(role.value ?? "");

  if (role.type === "email") {
    return (
      <InputWithSave
        value={internalValue}
        onChange={setInternalValue}
        disabled={!onChange}
        onSave={() => onChange!(internalValue)}
        placeholder="Enter email address"
      />
    );
  }

  return (
    <EmployeeSelect
      style={{ width: "100%" }}
      value={internalValue ?? undefined}
      disabled={!onChange}
      onSelect={id => {
        setInternalValue(id);
        return onChange!(id);
      }}
      placeholder="No employee selected"
    />
  );
};
