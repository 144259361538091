import { Editor } from "@tiptap/react";
import { ReactComponent as BoldIcon } from "./assets/bold.svg";
import { ReactComponent as ItalicIcon } from "./assets/italic.svg";
import { ReactComponent as UnderlineIcon } from "./assets/underline.svg";
import { ReactComponent as ListIcon } from "./assets/list.svg";
import { ReactComponent as OrderedListIcon } from "./assets/list-ordered.svg";
import { EmojiSelector } from "./EmojiSelector";
import { LinkSelector } from "./LinkSelector";
import { FileSelector } from "./FileSelector";
import { EmojiData } from "emoji-mart";
import { GifSelector } from "./GifSelector";
import { FieldSelector } from "./FieldSelector";
import { FontSizeSelector } from "components/editors/FontSizeSelector/FontSizeSelector";
import { ColorSelector } from "./ColorSelector";
import { BackgroundSelector } from "./BackgroundSelector";
import { TextAlignSelector } from "./TextAlignSelector";

export interface ToolbarItem {
  key: string;
  type: "button" | "component";
  component?: (editor: Editor) => React.ReactNode;
  icon?: React.ReactNode;
  action?: (editor: Editor) => void;
  isActive?: (editor: Editor) => boolean;
  isEnabled?: (editor: Editor) => boolean;
  show?: boolean;
}

export interface ToolbarConfigurations {
  underline?: boolean;
  fields?: boolean;
  emojis?: boolean;
  images?: boolean;
  gifs?: boolean;
  fontSize?: boolean;
  bold?: boolean;
  italic?: boolean;
  bulletList?: boolean;
  orderedList?: boolean;
  link?: boolean;
  color?: boolean;
  highlight?: boolean;
  textAlign?: boolean;
  backgroundColor?: boolean;
}

export const createToolbarItems = ({
  underline = true,
  fields = false,
  emojis = true,
  images = true,
  gifs = true,
  fontSize = true,
  bold = true,
  italic = true,
  bulletList = true,
  orderedList = true,
  link = true,
  color = true,
  highlight = true,
  textAlign = true,
  backgroundColor = true
}: ToolbarConfigurations): ToolbarItem[] => [
  {
    key: "fontSize",
    type: "component",
    component: editor => (
      <FontSizeSelector
        onSelected={option => {
          //@ts-ignore
          editor?.chain().focus().setFontSize(option.key).run();
        }}
      />
    ),
    show: fontSize
  },
  {
    key: "textAlign",
    type: "component",
    component: editor => (
      <TextAlignSelector
        onSelect={(alignment: string) => {
          editor?.commands.setTextAlign(alignment);
        }}
        alignment={editor?.getAttributes("paragraph").textAlign}
      />
    ),
    show: textAlign
  },
  {
    key: "color",
    type: "component",
    component: editor => (
      <ColorSelector
        onSelect={color => editor.chain().focus().setColor(color).run()}
        color={editor.getAttributes("textStyle").color}
        onClear={() => editor.chain().focus().unsetColor().run()}
      />
    ),
    show: color
  },
  {
    key: "backgroundColor",
    type: "component",
    component: editor => (
      <BackgroundSelector
        onSelect={color => editor.commands.setParagraphBackgroundColor(color)}
        onClear={() => editor.commands.unsetParagraphBackgroundColor()}
        color={editor.getAttributes("paragraph").backgroundColor || ""}
      />
    ),
    show: backgroundColor
  },
  {
    key: "bold",
    type: "button",
    icon: <BoldIcon />,
    action: editor => editor.chain().focus().toggleBold().run(),
    isActive: editor => editor.isActive("bold"),
    isEnabled: editor => editor.can().chain().focus().toggleBold().run(),
    show: bold
  },
  {
    key: "italic",
    type: "button",
    icon: <ItalicIcon />,
    action: editor => editor.chain().focus().toggleItalic().run(),
    isActive: editor => editor.isActive("italic"),
    isEnabled: editor => editor.can().chain().focus().toggleItalic().run(),
    show: italic
  },
  {
    key: "underline",
    type: "button",
    icon: <UnderlineIcon />,
    action: editor => editor.chain().focus().toggleUnderline().run(),
    isActive: editor => editor.isActive("underline"),
    isEnabled: editor => editor.can().chain().focus().toggleUnderline().run(),
    show: underline
  },
  {
    key: "bulletList",
    type: "button",
    icon: <ListIcon />,
    action: editor => editor.chain().focus().toggleBulletList().run(),
    isActive: editor => editor.isActive("bulletList"),
    isEnabled: editor => editor.can().chain().focus().toggleBulletList().run(),
    show: bulletList
  },
  {
    key: "orderedList",
    type: "button",
    icon: <OrderedListIcon />,
    action: editor => editor.chain().focus().toggleOrderedList().run(),
    isActive: editor => editor.isActive("orderedList"),
    isEnabled: editor => editor.can().chain().focus().toggleOrderedList().run(),
    show: orderedList
  },
  {
    key: "link",
    type: "component",
    component: editor => <LinkSelector editor={editor} />,
    show: link
  },
  {
    key: "emojis",
    type: "component",
    component: editor => (
      <EmojiSelector
        onInsert={(emoji: EmojiData) => {
          editor
            ?.chain()
            .focus()
            //@ts-ignore
            .addEmoji((emoji as any).native)
            .run();
        }}
      />
    ),
    show: emojis
  },

  {
    key: "gifs",
    type: "component",
    component: editor => (
      <GifSelector
        onInsert={gif => {
          //@ts-ignore
          editor?.chain().focus().setCustomImage({ src: gif }).run();
        }}
      />
    ),
    show: gifs
  },
  {
    key: "images",
    type: "component",
    component: editor => (
      <FileSelector
        onFileSelect={(url, name) => {
          //@ts-ignore
          editor?.chain().focus().setCustomImage({ src: url, alt: name, title: name }).run();
        }}
      />
    ),
    show: images
  },

  {
    key: "fields",
    type: "component",
    component: editor => (
      <FieldSelector
        onSelect={(path, title) => {
          //@ts-ignore
          editor?.chain().focus().addField(path, title).run();
          return true;
        }}
      />
    ),
    show: fields
  }
];
