import { Icon } from "@introist/react-foundation/v2";
import { ActionsRoute } from "./ActionsRoute";

export const SYSTEM_ROUTES = {
  actions: {
    key: "system-actions",
    title: "Actions",
    icon: <Icon name="files" />,
    path: "/system/actions",
    element: <ActionsRoute />
  }
};
