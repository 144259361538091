import { ModalProps, Modal, useToast, Field, Input, Stack } from "@introist/react-foundation/v2";
import { useCallback, useState } from "react";
import { useConnectAccount } from "modules/connectedaccounts/hooks/use-connect-account";
import styled from "styled-components";
import { saveSubAccount } from "../../../connectedaccounts";

type Props = ModalProps & {
  parentSenderId: string;
};

const MinWidthModal = styled(Modal)`
  min-width: 30rem;
`;

export const AddSendAsModal = ({ parentSenderId, onClose, ...props }: Props) => {
  const toast = useToast();

  const { refetchConnectedAccounts } = useConnectAccount();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const onSave = useCallback(async () => {
    try {
      await saveSubAccount(parentSenderId, { name, email });
      await refetchConnectedAccounts();
      onClose && onClose();
      toast.success("Send As added");
    } catch {
      toast.error("Failed to add account");
    }
  }, [refetchConnectedAccounts, parentSenderId, toast, onClose, name, email]);

  return (
    <MinWidthModal
      title="Add Send As account"
      primaryButton={{
        title: "Add",
        onClickWithLoading: onSave
      }}
      onClose={onClose}
      {...props}
    >
      <Stack vertical>
        <Field title="Name">
          <Input value={name} onChange={setName} autoFocus />
        </Field>
        <Field title="Email">
          <Input value={email} onChange={setEmail} />
        </Field>
      </Stack>
    </MinWidthModal>
  );
};
