import { Node, Command, mergeAttributes } from "@tiptap/core";

interface ImageOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands {
    "custom-image": {
      /**
       * Insert an image
       */
      setCustomImage: (options: { src: string; alt?: string; title?: string }) => Command;
    };
  }
}

export const Image = Node.create<ImageOptions>({
  name: "custom-image",

  group: "block", // Images behave like block-level elements
  inline: false,
  atom: true, // Treated as a single, uneditable unit

  addOptions() {
    return {
      HTMLAttributes: {} // Default HTML attributes
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute("src"),
        renderHTML: attributes => {
          if (!attributes.src) return {};
          return { src: attributes.src };
        }
      },
      alt: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute("alt"),
        renderHTML: attributes => {
          if (!attributes.alt) return {};
          return { alt: attributes.alt };
        }
      },
      title: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute("title"),
        renderHTML: attributes => {
          if (!attributes.title) return {};
          return { title: attributes.title };
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "img[src]" // Match <img> tags with a `src` attribute
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setCustomImage:
        options =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          });
        }
    };
  }
});
