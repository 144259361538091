import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Block, IntroistFeatherIcon } from "../../../../components/atoms";
import { Button, Stack } from "@introist/react-foundation/v2";
import { prefixForm } from "../../../../utils/FormUtils";
import { SheetModal } from "./SheetModal";
import { useState } from "react";

import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { FieldMappingModal, ReadOnlyFieldMapping } from "../../components/FieldMapping";

export const GoogleSheetRowStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const { fields } = useEmployeeFields();

  const [sheetSetupOpen, setSheetSetupOpen] = useState(false);
  const [mappingOpen, setMappingOpen] = useState(false);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Target">
          <StepEditorAccordion.Row label="Sheet">
            <Button
              variant="outlined"
              style={{ stroke: "black" }}
              startAdornment={<IntroistFeatherIcon icon="table" size={16} color="black" />}
              onClick={() => setSheetSetupOpen(true)}
            >
              {prefixedForm.get("fileId") !== ""
                ? `${prefixedForm.get("fileName")} - ${prefixedForm.get("sheetName")}`
                : "Set up a sheet"}
            </Button>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
        <SheetModal
          open={sheetSetupOpen}
          onClose={() => setSheetSetupOpen(false)}
          value={prefixedForm.get("sheetId")}
          onConnect={sheet => {
            prefixedForm.setMany({
              fileId: sheet.fileId,
              fileName: sheet.fileTitle,
              sheetId: sheet.sheetId,
              sheetName: sheet.sheetTitle
            });
          }}
        />
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Data">
          <ReadOnlyFieldMapping
            showHeader={false}
            sourceFields={fields ?? []}
            value={prefixedForm.get("fields") !== "" ? prefixedForm.get("fields") : []}
          />
          <Stack justifyContent="center">
            <Button variant="outlined" onClick={() => setMappingOpen(true)}>
              Edit mapping
            </Button>
          </Stack>
        </StepEditorAccordion>
        <FieldMappingModal
          open={mappingOpen}
          onClose={() => setMappingOpen(false)}
          sourceFields={fields ?? []}
          value={prefixedForm.get("fields") !== "" ? prefixedForm.get("fields") : []}
          onChange={prefixedForm.set("fields")}
          enableAdd
        />
      </Block>
    </>
  );
};
