import { ModalProps, Modal, Select, useToast } from "@introist/react-foundation/v2";
import { useEmailSenderApi } from "../../../services/api/Senders";
import { useCallback, useMemo, useState } from "react";
import { saveSubAccount } from "../../connectedaccounts/ConnectedAccountsApi";
import { useConnectAccount } from "modules/connectedaccounts/hooks/use-connect-account";
import styled from "styled-components";

type Props = ModalProps & {
  parentSenderId: string;
};

const MinWidthModal = styled(Modal)`
  min-width: 30rem;
`;

export const AddCalendarContainer = ({ parentSenderId, onClose, ...props }: Props) => {
  const toast = useToast();

  const emailSendersApi = useEmailSenderApi();
  const { data: calendars } = emailSendersApi.listCalendars({ hostId: parentSenderId }).query;

  const { refetchConnectedAccounts } = useConnectAccount();

  const [selectedCalendar, setSelectedCalendar] = useState("");

  const options = useMemo(() => {
    if (!calendars) return [];
    return calendars.map(cal => ({ key: cal.id, title: cal.name }));
  }, [calendars]);

  const onSave = useCallback(async () => {
    try {
      const cal = calendars!.find(cal => cal.id === selectedCalendar)!;
      await saveSubAccount(parentSenderId, { name: cal.name, email: cal.id });
      await refetchConnectedAccounts();
      onClose && onClose();
      toast.success("Calendar added");
    } catch {
      toast.error("Failed to add calendar");
    }
  }, [calendars, refetchConnectedAccounts, parentSenderId, selectedCalendar, toast, onClose]);

  return (
    <MinWidthModal
      title="Add calendar"
      description="Choose a calendar to add as a host"
      primaryButton={{
        title: "Add calendar",
        onClickWithLoading: onSave
      }}
      onClose={onClose}
      {...props}
    >
      <Select
        style={{ width: "100%" }}
        options={options}
        value={selectedCalendar}
        placeholder="Select calendar"
        onSelect={opt => setSelectedCalendar(opt.key)}
      />
    </MinWidthModal>
  );
};
