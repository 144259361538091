import { ListView, TopBarRoute } from "../../../components/templates";

import { TabBar } from "../../../components/molecules";
import { Stack } from "@introist/react-foundation/v2";
import { useSearch, useStoredState } from "../../../foundation";
import { z } from "zod";
import { UpcomingSteps } from "../views/UpcomingSteps";
import { AutomationStage, useAutomations } from "../hooks/useAutomations";
import { AutomationList } from "../components/AutomationList";
import { AutomationsTimeline } from "../views/AutomationsTimeline";

export const AutomationsRoute = () => {
  const [tab, setTab] = useStoredState<string>("intr.automations.tab", "active", z.string());

  return (
    <TopBarRoute paths={[{ key: "automations", title: "Automations" }]}>
      <Stack vertical>
        <div style={{ marginRight: "auto" }}>
          <TabBar
            tabs={[
              { id: "draft", title: "Drafts", iconName: "progress" },
              { id: "active", title: "Active", iconName: "trigger" },
              { id: "steps", title: "Steps", iconName: "stepType" },
              { id: "timeline", title: "Timeline", iconName: "calendar" }
            ]}
            activeTab={tab}
            onTabChange={setTab}
          />
        </div>

        {tab === "active" && <AutomationsList stage="active" title="Active automations" />}
        {tab === "draft" && <AutomationsList stage="draft" title="Drafts" />}
        {tab === "steps" && <UpcomingSteps />}
        {tab === "timeline" && <AutomationsTimeline />}
      </Stack>
    </TopBarRoute>
  );
};

type AutomationsListProps = {
  stage: AutomationStage;
  title?: string;
};

const AutomationsList = ({ stage, title = "Automations" }: AutomationsListProps) => {
  const { automations } = useAutomations({ stages: [stage!] });

  const { filtered, search, setSearch } = useSearch(automations, {
    fields: ["employeeName", "title"]
  });

  return (
    <Stack vertical gap="large">
      <ListView.Header
        title={title}
        count={filtered?.length}
        search={{
          search,
          setSearch
        }}
      />
      <AutomationList automations={filtered} />
    </Stack>
  );
};
