import { CategorisedOption, Icon } from "@introist/react-foundation/v2";
import { uniqBy } from "lodash";
import { Automation } from "../../hooks/useAutomations";
import { Workflow } from "../../../flows";

export const JOURNEYS_TIMELINE_FILTERS = [
  {
    title: "Employee",
    key: "employee",
    startAdornment: <Icon name="person" />
  },
  {
    title: "Workflow",
    key: "workflow",
    startAdornment: <Icon name="workflows" />
  }
];

export const buildJourneysTimelineFilterOptions = (
  timelineItem: Automation[],
  workflows: Workflow[]
) => {
  const uniqueEmployees = uniqBy(timelineItem, "employeeId");
  const employeeOptions: CategorisedOption[] = uniqueEmployees.map((i, idx) => ({
    key: i.employeeId || `deleted-employee--${idx}`,
    title: i.employeeName || "Deleted Employee",
    category: "employee"
  }));

  const workflowOptions: CategorisedOption[] = workflows.map(w => ({
    key: w.id,
    title: w.title,
    category: "workflow"
  }));

  return [...employeeOptions, ...workflowOptions];
};

export const filterJourneysTimeline = (
  timelineItems: Automation[],
  filters: CategorisedOption[]
) => {
  let filteredTimelineItems = filterByEmployee(timelineItems, filters);
  filteredTimelineItems = filterByWorkflows(filteredTimelineItems, filters);

  return filteredTimelineItems;
};

const filterByEmployee = (timelineItem: Automation[], filters: CategorisedOption[]) => {
  const employeeFilters = selectedFilters(filters, "employee");
  if (employeeFilters.length === 0) return timelineItem;

  return timelineItem.filter(t => {
    return employeeFilters.includes(t.employeeId || "deleted-employee");
  });
};

const filterByWorkflows = (timelineItem: Automation[], filters: CategorisedOption[]) => {
  const workflowFilters = selectedFilters(filters, "workflow");
  if (workflowFilters.length === 0) return timelineItem;

  return timelineItem.filter(t => {
    return workflowFilters.includes(t.workflowId);
  });
};

const selectedFilters = (filters: CategorisedOption[], category: string) => {
  return filters.filter(f => f.category === category).map(f => f.key);
};
