import { CommandProps, Node, mergeAttributes } from "@tiptap/core";

export const Field = Node.create({
  name: "field",

  group: "inline", // Inline group for embedding within text
  inline: true,
  atom: true, // Treated as a single unit

  addAttributes() {
    return {
      field: {
        default: null,
        parseHTML: element => element.getAttribute("data-property-field"),
        renderHTML: attributes => ({
          "data-property-field": attributes.field
        })
      },
      title: {
        default: null,
        parseHTML: element => element.getAttribute("data-property-title"),
        renderHTML: attributes => ({
          "data-property-title": attributes.title
        })
      },
      readonly: {
        default: "true",
        parseHTML: element => element.getAttribute("readonly") || "true",
        renderHTML: attributes => ({
          readonly: attributes.readonly
        })
      }
    };
  },

  // @ts-ignore
  parseHTML() {
    return [
      {
        tag: "field[data-property-field]",
        getContent: element => {
          // Ensure the element is an HTMLElement
          const htmlElement = element as HTMLElement;
          const span = htmlElement.querySelector('span[contenteditable="false"]');
          return span?.textContent || "";
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "field",
      mergeAttributes(HTMLAttributes, {
        contenteditable: "false" // The main field tag remains non-editable
      }),
      [
        "span",
        { contenteditable: "false" }, // Span attributes
        HTMLAttributes["data-property-title"] || "" // Inner content of the span
      ]
    ];
  },

  //@ts-ignore
  addCommands() {
    return {
      addField:
        (field: string, title: string) =>
        ({ commands }: CommandProps) => {
          return commands.insertContent({
            type: this.name,
            attrs: { field, title }
          });
        }
    };
  }
});
