import { Stack, IconButton, Popover } from "@introist/react-foundation/v2";
import { EmojiData, Picker } from "emoji-mart";
import { useState } from "react";
import { ReactComponent as EmojiIcon } from "./assets/laugh.svg";

export const EmojiSelector = ({ onInsert }: { onInsert: (emoji: EmojiData) => boolean | void }) => {
  const [emojiButtonRef, setEmojiButtonRef] = useState<HTMLElement | null>(null);
  const [emojiOpen, setEmojiOpen] = useState(false);

  return (
    <>
      <Stack ref={setEmojiButtonRef}>
        <IconButton size="large" onClick={() => setEmojiOpen(true)} icon={<EmojiIcon />} />
      </Stack>

      <Popover
        referenceElement={emojiButtonRef}
        open={emojiOpen}
        onClose={() => setEmojiOpen(false)}
        closeOnContentClick={false}
      >
        <Picker onSelect={onInsert} />
      </Popover>
    </>
  );
};
