import { H4, IconButton, Stack } from "@introist/react-foundation/v2";

export const DrawerHeader = ({ title, onClose }: { title: string; onClose?: () => void }) => {
  return (
    <Stack justifyContent="space-between">
      <H4>{title}</H4>
      {onClose && <IconButton icon="crossSmall" onClick={onClose} />}
    </Stack>
  );
};
