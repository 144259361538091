import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { IconName, Option, useToast } from "@introist/react-foundation/v2";

export type EmployeeRole = RouterOutput["employees"]["roles"]["list"][0] & {
  icon: IconName;
};

export type EmployeeRoleType = RouterOutput["employees"]["roles"]["list"][0]["type"];

export const EmployeeRoleTypes: Option[] = [
  { key: "employee", title: "Employee" },
  { key: "email", title: "Email address" }
];

export type EmployeeRoleUpdate = RouterInput["employees"]["roles"]["update"]["update"];

const withIcon = (role: any): EmployeeRole => {
  if (!role) return role;
  const icon = role.type === "email" ? "email" : "personSync";
  return { ...role, icon };
};

export const useRoles = () => {
  const { data: roles } = api.employees.roles.list.useQuery({});

  return {
    roles: (roles ?? []).map(withIcon) as EmployeeRole[]
  };
};

export const useRole = (id?: string) => {
  const { data: role } = api.employees.roles.find.useQuery({ id: id! }, { enabled: !!id });

  return { role: withIcon(role) };
};

export const useRoleActions = () => {
  const toast = useToast();

  const createOp = api.employees.roles.create.useMutation();
  const updateOp = api.employees.roles.update.useMutation();

  const apiUtils = api.useContext();

  const findOp = apiUtils.employees.roles.find;
  const listOp = apiUtils.employees.roles.list;

  const create = async (data: any) => {
    return createOp
      .mutateAsync(data)
      .then(async created => {
        await listOp.refetch();
        toast.success("Role created");
        return withIcon(created);
      })
      .catch(err => {
        toast.error("Failed to create role");
        throw err;
      });
  };

  const update = async (id: string, update: EmployeeRoleUpdate) => {
    return updateOp
      .mutateAsync({ id, update })
      .then(async updated => {
        await listOp.refetch();
        await findOp.refetch({ id });
        return withIcon(updated);
      })
      .catch(() => {
        toast.error("Failed to update role");
      });
  };

  return { create, update };
};
