import { useState } from "react";
import { IconButton, Stack } from "@introist/react-foundation/v2";
import { ReactComponent as AIcon } from "./assets/baseline.svg";
import { HexColorPickerPopover } from "./components/HexColorPickerPopover";

export const ColorSelector = ({
  color,
  onSelect,
  onClear
}: {
  color: string;
  onSelect: (color: string) => void;
  onClear: () => void;
}) => {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Stack ref={setPopoverRef}>
        <IconButton
          icon={
            <AIcon
              style={{
                stroke: color
              }}
            />
          }
          onClick={handleButtonClick}
          size="large"
          active={!!color}
        />
      </Stack>
      <HexColorPickerPopover
        referenceElement={popoverRef}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        onSelect={onSelect}
        onClear={onClear}
        color={color}
      />
    </>
  );
};
