import {
  authHeaders,
  generateMutation,
  generateQueryWithParameters,
  getBaseUrl,
  getJson,
  postJson
} from "./ApiBase";
import axios from "axios";
import { UploadedFile, UploadProgressHandlers } from "./FileApiV2";

export interface FormField {
  id: string;
  title: string;
  description: string;
  type: "text" | "email" | "date" | "longtext" | "number" | "file";
  options: string[];
  required: boolean;
}

export interface Form {
  title: string;
  description: string;
  fields: FormField[];
  values: { [key: string]: string };
}

export const getFormResponse = ({ responseId }: { responseId: string }): Promise<Form> => {
  return getJson(`/form/${responseId}`);
};

export const uploadFormFile = async (
  responseId: string,
  file: File,
  axiosConfig: UploadProgressHandlers
): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append("file", file);
  const headers = await authHeaders();
  return axios
    .post(`/form/${responseId}/upload`, formData, {
      baseURL: getBaseUrl({}),
      headers: {
        ...headers,
        "content-type": "multipart/form-data"
      },
      ...axiosConfig
    })
    .then(res => {
      return res.data;
    });
};

export const submitFormResponse = ({
  responseId,
  submission
}: {
  responseId: string;
  submission: { [key: string]: string | undefined };
}): Promise<any> => {
  return postJson(`/form/${responseId}`, submission);
};

export const usePublicFormApi = () => ({
  find: generateQueryWithParameters<{ responseId: string }, Form>(
    ["formresponse"],
    getFormResponse
  ),
  submit: generateMutation(submitFormResponse)
});
