import { Stack, IconButton, Popover, Card, OptionList } from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { ReactComponent as AlignCenterIcon } from "./assets/align-center.svg";
import { ReactComponent as AlignLeftIcon } from "./assets/align-left.svg";
import { ReactComponent as AlignRightIcon } from "./assets/align-right.svg";

export const TextAlignSelector = ({
  onSelect,
  alignment = "left"
}: {
  onSelect: (alignment: string) => void;
  alignment?: string;
}) => {
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const activeIcon = useMemo(() => {
    if (alignment === "right") return <AlignRightIcon />;
    if (alignment === "center") return <AlignCenterIcon />;
    return <AlignLeftIcon />;
  }, [alignment]);

  return (
    <>
      <Stack ref={setButtonRef}>
        <IconButton size="large" onClick={() => setOpen(true)} icon={activeIcon} />
      </Stack>

      <Popover
        referenceElement={buttonRef}
        open={open}
        onClose={() => setOpen(false)}
        closeOnContentClick={false}
      >
        <Card style={{ padding: 0 }} elevated>
          <OptionList
            options={[
              {
                key: "Left",
                startAdornment: <AlignLeftIcon />,
                onClick: () => {
                  onSelect("left");
                }
              },
              {
                key: "Center",
                startAdornment: <AlignCenterIcon />,
                onClick: () => {
                  onSelect("center");
                }
              },
              {
                key: "Right",
                startAdornment: <AlignRightIcon />,
                onClick: () => {
                  onSelect("right");
                }
              }
            ]}
          />
        </Card>
      </Popover>
    </>
  );
};
