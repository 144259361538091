import { DataForm, Stack } from "@introist/react-foundation/v2";
import { FileUpload } from "../../components/FileUpload";
import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { AttachmentList } from "./components/AttachmentList";

import { useFiles } from "./hooks/useFiles";
import { FileType } from "modules/workflows/components/FileUpload/hooks/useFileUploadRestrictions";
import { useMemo } from "react";
import { EmployeeFieldSelector } from "../../../employees/fields/EmployeeFieldSelector";

interface IAttachmentsBlockProps {
  readOnly?: boolean;
  form: DataForm<any>;
}

const ACCEPTED_FILE_TYPES: FileType[] = [
  ".jpg",
  ".jpeg",
  ".png",
  ".pdf",
  ".doc",
  ".docx",
  ".pptx",
  ".pptm",
  ".ppt"
];
const MAX_NUMBER_OF_FILES = 5;
const MAX_FILE_SIZE_BYTES = 9.5 * 1024 * 1024; // 8MB to support larger files such as presentations

export const AttachmentsBlock = ({ form, readOnly, ...rest }: IAttachmentsBlockProps) => {
  const { files, addFiles, removeFile } = useFiles();

  const maxSizeInMb = Math.floor(MAX_FILE_SIZE_BYTES / 1000000);

  const attachmentCount = useMemo(() => {
    return form.get("attachments").length;
  }, [form]);

  return (
    <Block blended compact {...rest}>
      <StepEditorAccordion
        title="Attachments"
        description={attachmentCount > 0 ? attachmentCount : undefined}
        defaultOpen={false}
      >
        <AttachmentList
          readOnly={readOnly}
          files={files}
          attachments={form.get("attachments")}
          onUpdate={(file, fileId) => {
            form.set("attachments")([...form.get("attachments"), fileId]);
            removeFile(file);
          }}
          onRemove={fileId => {
            form.set("attachments")(form.get("attachments").filter((id: string) => id !== fileId));
          }}
        />
        {!readOnly && (
          <Stack vertical gap="small">
            <FileUpload
              title="Upload files"
              disabled={attachmentCount >= MAX_NUMBER_OF_FILES}
              onFileUploaded={addFiles}
              maxSize={MAX_FILE_SIZE_BYTES}
              acceptedFileTypes={ACCEPTED_FILE_TYPES}
              restrictions={[
                `Max file size ${maxSizeInMb}MB`,
                `Extensions: ${ACCEPTED_FILE_TYPES.join(" ")}`
              ]}
            />
            <EmployeeFieldSelector
              onSelect={path => {
                form.set("attachments")([...form.get("attachments"), `field:${path}`]);
              }}
              fieldTypes={["file"]}
            />
          </Stack>
        )}
      </StepEditorAccordion>
    </Block>
  );
};
