import { useCallback, useState } from "react";
import { Button, Card, IconButton, Input, Popover, Stack } from "@introist/react-foundation/v2";
import { ReactComponent as LinkIcon } from "./assets/link-2.svg";
import { Editor } from "@tiptap/react";

export const LinkSelector = ({ editor }: { editor?: Editor | null }) => {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState<string>();

  const onSubmit = useCallback(
    (link?: string) => {
      if (!editor) return;
      if (!link) {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      // update link
      editor.chain().focus().extendMarkRange("link").setLink({ href: link }).run();
    },
    [editor]
  );

  const handleSubmit = () => {
    onSubmit(link);
    setOpen(false);
    setLink(undefined);
  };

  return (
    <>
      <Stack ref={setPopoverRef}>
        <IconButton
          icon={<LinkIcon />}
          onClick={() => {
            setOpen(!open);
            setLink(editor?.getAttributes("link").href ?? undefined);
          }}
          size="large"
          active={!!editor?.isActive("link")}
        />
      </Stack>
      <Popover
        referenceElement={popoverRef}
        open={open}
        onClose={() => setOpen(false)}
        closeOnContentClick={false}
      >
        <Card elevated style={{ padding: "var(--spacing-xSmall)" }}>
          <Stack gap="xSmall">
            <Input
              value={link}
              onChange={setLink}
              size="small"
              placeholder="Enter link"
              variant="blended"
              autoFocus
              onKeyUp={e => {
                console.log(e.key);
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />

            <Button size="small" variant="blended" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </Card>
      </Popover>
    </>
  );
};
