import {
  ActionMenu,
  Button,
  Field,
  H4,
  IconButton,
  Input,
  LabelledCheckbox,
  Select,
  Stack,
  TextArea,
  useActions
} from "@introist/react-foundation/v2";
import {
  FormField,
  FormFieldCreate,
  FormFieldOptions,
  FormFieldUpdate,
  useFormField,
  useFormFieldActions
} from "../../useFormFields";

import { UnsavedChanges } from "../../../../components/molecules";
import { useObjectForm } from "./useObjectForm";
import { useMemo } from "react";
import { useEmployeeFields } from "modules/employees/hooks/useEmployeeFields";

export const FormFieldEditor = ({
  formId,
  fieldId,
  onClose
}: {
  formId: string;
  fieldId: string;
  onClose: () => unknown;
}) => {
  const { fields } = useEmployeeFields();

  const employeeFieldOptions = useMemo(() => {
    if (!fields || fields.length === 0) return [];
    return fields.map(field => ({ key: field.key, title: field.title }));
  }, [fields]);

  const { onConfirmAction } = useActions();

  const { field } = useFormField(fieldId, { enabled: fieldId && fieldId !== "new" });
  const { create, update, archive } = useFormFieldActions();

  const { value, onChange, hasChanges, submit, errors } = useObjectForm<FormField>(
    field,
    fieldId === "new" ? { type: "text" } : {}
  );

  const onSave = submit(async changes => {
    if (fieldId === "new") {
      return create({ ...changes, formId } as FormFieldCreate).then(onClose);
    } else {
      return update({ id: fieldId!, update: changes as FormFieldUpdate["update"] });
    }
  });

  const onArchive = onConfirmAction(
    async () => {
      await archive(fieldId!).then(onClose);
    },
    {
      title: "Confirm archive field",
      description: "Are you sure to archive this field?"
    }
  );

  return (
    <Stack vertical style={{ padding: "var(--spacing-xLarge)" }}>
      <Stack>
        <H4>{fieldId === "new" ? "New Form Field" : field?.title}</H4>
        <Stack gap="small" style={{ marginLeft: "auto" }}>
          {fieldId !== "new" && (
            <ActionMenu
              options={[
                {
                  key: "archive",
                  title: "Archive field",
                  startAdornmentIcon: "archive",
                  onClick: onArchive
                }
              ]}
            />
          )}
          <IconButton style={{ marginLeft: "auto" }} icon="crossSmall" onClick={onClose} />
        </Stack>
      </Stack>

      <Field title="Title" error={errors.title}>
        <Input
          value={value.title ?? ""}
          onChange={onChange("title")}
          autoFocus={fieldId === "new"}
          error={!!errors.title}
        />
      </Field>
      <Field title="Short Title (optional)">
        <Input
          size="small"
          placeholder="Short tag for internal use"
          value={value.shortTitle ?? ""}
          onChange={onChange("shortTitle")}
          error={!!errors.shortTitle}
        />
      </Field>
      <Field title="Description" error={errors.description}>
        <TextArea
          placeholder="Extra information for the submitter"
          value={value.description ?? ""}
          onChange={onChange("description")}
          error={!!errors.description}
        />
      </Field>
      <Field title="Type" error={errors.type}>
        <Select
          style={{ width: "100%" }}
          options={FormFieldOptions}
          value={value.type}
          onSelect={opt => onChange("type")(opt.key)}
          error={!!errors.type}
        />
      </Field>
      <Field title="Prefill" error={errors.prefill}>
        <Select
          style={{ width: "100%" }}
          options={employeeFieldOptions}
          value={value.prefill ?? undefined}
          onSelect={opt => onChange("prefill")(opt.key)}
          onClear={() => onChange("prefill")(null)}
          error={!!errors.prefill}
          searchable
        />
      </Field>

      <LabelledCheckbox
        label="This field is required"
        checked={value.required ?? false}
        onChange={onChange("required")}
        style={{ margin: "var(--spacing-medium) 0" }}
      />

      <OptionField
        value={value.options ?? []}
        onChange={onChange("options")}
        error={errors.options}
      />
      <UnsavedChanges
        show={hasChanges}
        onSave={onSave}
        style={{
          position: "sticky",
          bottom: "1rem",
          width: "250px",
          left: "calc(50% - 125px)"
        }}
      />
    </Stack>
  );
};

const OptionField = ({
  value,
  onChange,
  error
}: {
  value: string[];
  onChange: (options: string[]) => unknown;
  error?: string;
}) => (
  <Field title="Options" error={error}>
    {value.map((option, index) => (
      <Stack key={index}>
        <Input
          size="small"
          value={option}
          onChange={newVal => {
            const newOptions = value.map((o, i) => (index === i ? newVal : o));
            onChange(newOptions);
          }}
          style={{ flex: 1 }}
          placeholder="Option value"
        />
        <IconButton
          icon="crossSmall"
          onClick={() => {
            onChange(value.filter(o => o !== option));
          }}
        />
      </Stack>
    ))}

    <Button variant="blended" startIcon="plus" onClick={() => onChange([...value, ""])}>
      Add option
    </Button>
  </Field>
);
