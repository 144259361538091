import { useCallback } from "react";
import { CompanyEventSetting } from "services/rpc/RpcProvider";
import {
  PresentersSetting,
  MaxAttendees,
  RescheduleAttendeeOnDecline,
  RescheduleAttendeeOnWaiting
} from "../settings";
import { CancelOnPresenterDecline } from "../settings/CancelOnPresenterDecline";
import { MoveToWorkday } from "../settings/MoveToWorkday";

export const useCompanyEventSetting = () => {
  const getEventSetting = useCallback((setting: CompanyEventSetting) => {
    const settingKey = setting.setting;
    switch (settingKey) {
      case "RescheduleAttendeeOnDecline":
        return <RescheduleAttendeeOnDecline key={setting.setting} setting={setting} />;
      case "RescheduleAttendeeOnWaiting":
        return <RescheduleAttendeeOnWaiting key={setting.setting} setting={setting} />;
      case "MaxAttendees":
        return <MaxAttendees key={setting.setting} setting={setting} />;
      // @ts-ignore
      case "Presenters":
        // @ts-ignore
        return <PresentersSetting key={setting.setting} setting={setting} />;
      case "CancelOnPresenterDecline":
        return <CancelOnPresenterDecline key={setting.setting} setting={setting} />;
      case "MoveToWorkday":
        return <MoveToWorkday key={setting.setting} setting={setting} />;
      default:
        // eslint-disable-next-line no-console
        console.warn(`Unknown event setting: ${settingKey}`);
        return null;
    }
  }, []);
  return { getEventSetting };
};
