import { useEmployees } from "../hooks/useEmployees";
import { CircularLoader, Select, SelectProps } from "@introist/react-foundation/v2";
import { SelectorIcon } from "../fields/EmployeeFieldSelector";
import { useState } from "react";

export const EmployeeSelect = ({
  value,
  onSelect,
  endAdornment,
  ...props
}: Omit<SelectProps, "value" | "onSelect" | "options"> & {
  value?: string;
  onSelect: (id: string) => unknown;
}) => {
  const { employees } = useEmployees({});

  const [selecting, setSelecting] = useState(false);

  return (
    <Select
      searchable
      options={employees.map(e => ({
        key: e.id!,
        title: e.name!,
        startAdornment: <SelectorIcon icon="person" />
      }))}
      startAdornment={<SelectorIcon icon="person" />}
      value={value}
      endAdornment={selecting ? <CircularLoader size="tiny" /> : endAdornment}
      onSelect={opt => {
        setSelecting(true);
        Promise.resolve(onSelect(opt.key)).finally(() => setSelecting(false));
      }}
      {...props}
    />
  );
};
