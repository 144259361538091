import { AttachmentItem } from "./AttachmentItem";
import { FileBase } from "./FileBase";
import { FileUploadItem } from "./FileUploadItem";
import { useFormFieldResolver } from "../../../../../forms/useFormFields";
import { withoutPrefix } from "../../../../../../utils/Utils";

type FileProps = {
  readOnly?: boolean;
  attachmentId?: string;
  file?: File;
  onUploaded?: (fileId: string) => unknown;
  onRemove: (fileId: string) => unknown;
};

export const FileItem = ({ attachmentId, file, readOnly, onUploaded, onRemove }: FileProps) => {
  const { resolveTitle } = useFormFieldResolver();

  if (attachmentId && attachmentId.startsWith("field:")) {
    const field = withoutPrefix(attachmentId, "field:forms.");

    //eslint-disable-next-line
    const [formId, fieldId] = field.split(".");

    return <FileBase title={resolveTitle(fieldId, true)} onRemove={() => onRemove(attachmentId)} />;
  }

  if (attachmentId) {
    return <AttachmentItem id={attachmentId} readOnly={readOnly} onRemove={onRemove} />;
  }

  if (file) {
    return (
      <FileUploadItem
        file={file}
        onFileUploaded={file => {
          onUploaded && onUploaded(file.id);
        }}
      />
    );
  }

  return <FileBase title="NOT FOUND" />;
};
