import { authHeaders, getBaseUrl } from "./ApiBase";
import axios, { AxiosRequestConfig } from "axios";

export interface UploadedFile {
  id: string;
  name: string;
  type: string;
}

export const asFileIdentifier = (file: UploadedFile) => `${file.id}/${file.name}`;

export type UploadProgressHandlers = Pick<
  AxiosRequestConfig<File>,
  "onDownloadProgress" | "onUploadProgress"
>;

export const uploadFile = async (
  file: File,
  axiosConfig: UploadProgressHandlers
): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append("file", file);
  const headers = await authHeaders();
  return axios
    .post("/v2/files", formData, {
      baseURL: getBaseUrl({}),
      headers: {
        ...headers,
        "content-type": "multipart/form-data"
      },
      ...axiosConfig
    })
    .then(res => {
      return res.data;
    });
};
