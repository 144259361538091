import { CategorisedOption, Stack, TableActionBar } from "@introist/react-foundation/v2";
import { JourneysTimeline, JourneysTimelineLoader } from "./JourneysTimeline";
import { useState } from "react";
import useMeasure from "react-use-measure";
import {
  JOURNEYS_TIMELINE_FILTERS,
  buildJourneysTimelineFilterOptions,
  filterJourneysTimeline
} from "./filters";
import { Empty } from "../../../../foundation";
import { useAutomations } from "../../hooks/useAutomations";
import { useWorkflows } from "../../../flows/hooks/useWorkflows";

export const AutomationsTimeline = () => {
  const { automations } = useAutomations({ stages: ["active"] });
  const { workflows } = useWorkflows({});

  const [graphContainerRef, { width }] = useMeasure();

  const [filters, setFilters] = useState<CategorisedOption[]>([]);

  const filteredTimelineData = filterJourneysTimeline(automations ?? [], filters);

  return (
    <Stack vertical>
      <TableActionBar
        title="Timeline"
        description={filteredTimelineData ? filteredTimelineData.length.toString() : undefined}
        filter={{
          options: buildJourneysTimelineFilterOptions(automations ?? [], workflows ?? []),
          categories: JOURNEYS_TIMELINE_FILTERS,
          value: filters,
          onFilterChange: setFilters
        }}
      />
      <Stack vertical>
        {automations === undefined ? (
          <JourneysTimelineLoader />
        ) : (
          <div style={{ position: "relative" }}>
            <div
              ref={graphContainerRef}
              style={{
                width: "100%",
                position: "absolute"
              }}
            >
              {filteredTimelineData.length > 0 ? (
                <JourneysTimeline data={filteredTimelineData} maxWidth={width} />
              ) : (
                <Empty icon="list" title="No active automations" message="" />
              )}
            </div>
          </div>
        )}
      </Stack>
    </Stack>
  );
};
