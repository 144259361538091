import { HtmlEditor } from "components/organisms";
import { useFeatureFlag } from "hooks";
import styled from "styled-components";
import { HtmlEditor as HtmlEditorV4 } from "components/editors/v4/HtmlEditor/HtmlEditor";

const StyledHTMLEditor = styled(HtmlEditor)`
  .ql-container {
    background-color: var(--palette-surface-subdued) !important;
  }
`;

export const ReadOnlyHTMLEditor = ({ html }: { html: string }) => {
  const htmlEditorV4 = useFeatureFlag("html-editor-v4");
  if (htmlEditorV4.isEnabled) return <HtmlEditorV4 content={html} readonly />;
  return <StyledHTMLEditor defaultValue={html} readonly={true} />;
};
