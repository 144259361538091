import styled from "styled-components";
import { Icon, Input, InputProps, useTheme, useToast } from "@introist/react-foundation/v2";
import { useCallback, useMemo, useState } from "react";
import { UploadedFile, uploadFile, UploadProgressHandlers } from "../../../services/api/FileApiV2";
import { ProgressBar } from "../../../components/molecules";
import { AxiosProgressEvent } from "axios";

const FileInput = styled(Input)`
  ::file-selector-button {
    display: none;
  }
  font-family: var(--typography-font-text);
`;

type StoredFileInputProps = Omit<InputProps, "onChange"> & {
  uploadHandler?: (file: File, progressHandlers: UploadProgressHandlers) => Promise<UploadedFile>;
  onChange: (file: UploadedFile) => unknown;
};

// @ts-ignore
const countProgress = (e: AxiosProgressEvent) => Math.round((e.loaded / e.total) * 100);

export const StoredFileInput = ({
  onChange,
  value,
  uploadHandler,
  ...props
}: StoredFileInputProps) => {
  const toast = useToast();
  const { theme } = useTheme();

  const [progress, setProgress] = useState<undefined | number>();
  const [error, setError] = useState(false);

  const onUpload = useCallback(
    async (val, e) => {
      const files = e.target.files;
      if (files && files.length > 0) {
        setProgress(0);
        setError(false);

        const progressHandlers: UploadProgressHandlers = {
          onUploadProgress: e => setProgress(countProgress(e)),
          onDownloadProgress: e => setProgress(countProgress(e))
        };

        const upload = uploadHandler ?? uploadFile;

        upload(files[0], progressHandlers)
          .then(uploadedFile => {
            onChange(uploadedFile);
            setProgress(undefined);
          })
          .catch(() => {
            toast.error("Failed to upload file");
            setError(true);
          });
      }
    },
    [onChange, uploadHandler, toast]
  );

  const end = useMemo(() => {
    if (error) return <Icon name="warning" color={theme.palette.danger.default} />;
    if (progress) return <ProgressBar style={{ width: "16px" }} value={progress} total={100} />;
    return props.endAdornment;
  }, [error, progress, props.endAdornment, theme.palette]);

  return (
    <FileInput
      type="file"
      {...props}
      onChange={onUpload}
      endAdornment={end}
      error={props.error || error}
    />
  );
};
