import { Popover, Card, Stack, Input, IconButton } from "@introist/react-foundation/v2";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

export const HexColorPickerPopover = ({
  referenceElement,
  open,
  onClose,
  onSelect,
  onClear,
  color
}: {
  referenceElement: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onSelect: (color: string) => void;
  onClear: () => void;
  color?: string;
}) => {
  const [inputValue, setInputValue] = useState(color || "");
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    setInputValue(color || "");
  }, [color]);

  const handleInputChange = (value: string) => {
    let formattedValue = value.trim();
    const hexWithoutHashRegex = /^([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
    const hexWithHashRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;

    if (hexWithoutHashRegex.test(formattedValue)) {
      formattedValue = `#${formattedValue}`;
    }
    setInputValue(formattedValue);
    setInputError(false);

    if (hexWithHashRegex.test(formattedValue)) {
      onSelect(formattedValue);
    } else {
      setInputError(true);
    }
  };
  return (
    <Popover
      placement="top"
      referenceElement={referenceElement}
      onClose={onClose}
      open={open}
      closeOnContentClick={false}
    >
      <Card elevated style={{ padding: "var(--spacing-small)" }}>
        <Stack vertical justifyContent="center" alignItems="center">
          <HexColorPicker onChange={onSelect} color={color} />
          <Stack gap="xSmall">
            <Input
              value={inputValue}
              size="small"
              variant="blended"
              placeholder="Submit #color"
              onChange={handleInputChange}
              error={inputError}
            />
            <IconButton icon="trash" size="large" onClick={onClear} />
          </Stack>
        </Stack>
      </Card>
    </Popover>
  );
};
