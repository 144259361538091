import React from "react";
import { IconButton, Stack } from "@introist/react-foundation/v2";
import { Editor } from "@tiptap/react";
import { createToolbarItems, ToolbarConfigurations } from "./ToolbarConfig";
import styled from "styled-components";

const ToolbarWrapper = styled(Stack)`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const Toolbar = ({
  editor,
  toolbarConfigurations
}: {
  editor: Editor | null;
  toolbarConfigurations: ToolbarConfigurations;
}) => {
  if (!editor) return null;

  const toolbarItems = createToolbarItems(toolbarConfigurations);
  return (
    <ToolbarWrapper gap="small">
      {toolbarItems
        .filter(_ => !!_.show)
        .map((item, index) => {
          if (item.type === "button") {
            return (
              <IconButton
                key={index}
                size="large"
                onClick={() => item.action?.(editor)}
                disabled={item.isEnabled ? !item.isEnabled(editor) : false}
                active={item.isActive ? item.isActive(editor) : false}
                icon={item.icon}
              />
            );
          }

          if (item.type === "component" && item.component) {
            return <React.Fragment key={index}>{item.component(editor)}</React.Fragment>;
          }

          return null;
        })}
    </ToolbarWrapper>
  );
};
