import { useRef } from "react";
import { IconButton, Stack } from "@introist/react-foundation/v2";
import { ReactComponent as ImageIcon } from "./assets/image-up.svg";
import { uploadAsset } from "services/api/AssetsApi";

export const FileSelector = ({
  onFileSelect
}: {
  onFileSelect: (url: string, name: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = await uploadAsset(file as any);
      onFileSelect(url, file.name);
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <Stack>
        <IconButton icon={<ImageIcon />} onClick={handleButtonClick} size="large" />
      </Stack>

      {/* Hidden file input */}
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </>
  );
};
