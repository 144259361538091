import { MouseEvent, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import { SkeletonLoader, Stack, useTheme } from "@introist/react-foundation/v2";
import { useTransition, a, config } from "@react-spring/web";

import { Axis } from "./Axis";
import { FocusLine } from "./FocusLine";
import { JourneysTimelineTooltip } from "./JourneysTimelineTooltip";
import { JourneysTimelineEntry } from "./JourneysTimelineEntry";
import { CurrentDateMarker } from "./CurrentDateMarker";
import { useChartDimensions, useChartTooltip } from "./hooks";
import { Automation } from "../../../hooks/useAutomations";
import { orderBy } from "lodash";

type Props = {
  data: Automation[];
  sort?: "time" | "workflow";
  maxWidth?: number;
};

const ENTRY_HEIGHT = 32;
const ENTRY_GAP = 10;
const MARGIN = 24;

export const JourneysTimeline = ({ data, sort, maxWidth, ...rest }: Props) => {
  const height = Math.max(data.length * (ENTRY_HEIGHT + ENTRY_GAP) + ENTRY_HEIGHT + ENTRY_GAP, 500);
  const heightRef = useRef(height);

  const { theme } = useTheme();
  const { ref, dimensions } = useChartDimensions({
    marginTop: 32,
    width: maxWidth,
    marginLeft: MARGIN,
    marginRight: MARGIN,
    height
  });
  const { tooltip, showTooltip, hideTooltip } = useChartTooltip();

  const [focusLine, setFocusLine] = useState<{
    visible: boolean;
    xPosition: number;
    currentDate: string | null;
  }>({ visible: false, xPosition: 0, currentDate: null });

  const earliestDate = d3.min(data, d => d.stats?.firstStepAt);
  const latestDate = d3.max(data, d => d.stats?.lastStepAt);

  const xScale = useMemo(() => {
    const adjustedEarliestDate = new Date(earliestDate!);
    adjustedEarliestDate.setMonth(adjustedEarliestDate.getMonth() - 1);

    const adjustedLatestDate = new Date(latestDate!);
    adjustedLatestDate.setMonth(adjustedLatestDate.getMonth() + 1);

    return d3
      .scaleUtc()
      .domain([adjustedEarliestDate, adjustedLatestDate])
      .range([0, dimensions.boundedWidth])
      .nice();
  }, [earliestDate, latestDate, dimensions.boundedWidth]);

  const sortedData = orderBy(data, e => e.stats?.firstStepAt);

  const sortedEntries = useMemo(() => {
    if (sort === "workflow") {
      const groupedData = d3
        .groups(sortedData, d => d.title)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
      return groupedData.flatMap(([_, values]) => values);
    } else {
      return sortedData;
    }
  }, [sort, sortedData]);

  // Animations
  const transitions = useTransition(sortedEntries, {
    keys: entry => entry.id,
    from: { opacity: 0, transform: "translate3d(0,0px,0)" },
    enter: item => async next => {
      const idx = sortedEntries.findIndex(entry => entry.id === item.id);
      const transformY = idx * (ENTRY_HEIGHT + ENTRY_GAP);

      await next({ opacity: 1, transform: `translate3d(0,${transformY}px,0)` });
    },
    update: item => async next => {
      const idx = sortedEntries.findIndex(entry => entry.id === item.id);
      const transformY = idx * (ENTRY_HEIGHT + ENTRY_GAP) + ENTRY_GAP;
      await next({ transform: `translate3d(0,${transformY}px,0)` });
    },
    leave: { opacity: 0 },
    config: { clamp: true, ...config.stiff },
    immediate: heightRef.current !== height
  });

  // Update focus line position
  const handleMouseMove = (event: MouseEvent<SVGSVGElement>) => {
    const svg = event.currentTarget;
    const rect = svg.getBoundingClientRect();
    let xPosition = event.clientX - rect.left - dimensions.marginLeft;

    // Clamp xPosition to stay within the graph's bounds
    xPosition = Math.max(0, Math.min(xPosition, dimensions.boundedWidth));

    const currentDate = xScale.invert(xPosition);
    const formattedDate = d3.utcFormat("%b %d, %y")(currentDate);

    setFocusLine({
      ...focusLine,
      xPosition: xPosition,
      currentDate: formattedDate
    });
  };

  // Show focus line
  const handleMouseEnter = () => {
    setFocusLine({ ...focusLine, visible: true });
  };

  // Hide focus line
  const handleMouseLeave = () => {
    setFocusLine({ ...focusLine, visible: false });
  };

  // Show & update tooltip position
  const handleOnMouseMoveEntry = (
    event: MouseEvent<SVGGElement>,
    entry: Automation,
    sx: number,
    transformY: number,
    idx: number
  ) => {
    const svgRect = event.currentTarget.getBoundingClientRect();
    const xPosition = event.clientX - svgRect.left;

    const x = sx + xPosition;
    const tY = transformY + (ENTRY_GAP * idx + ENTRY_GAP) + dimensions.marginTop + ENTRY_HEIGHT;

    showTooltip(entry, x, tY);
  };

  const handleOnMouseLeaveEntry = () => {
    hideTooltip();
  };

  return (
    <div {...rest} ref={ref} style={{ height, position: "relative", width: "100%" }}>
      <svg
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        width={dimensions.width}
        height={dimensions.height}
        viewBox={`0, 0, ${dimensions.width}, ${dimensions.height}`}
        style={{
          overflow: "visible"
        }}
      >
        <g transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}>
          <rect
            width={dimensions.boundedWidth}
            height={dimensions.boundedHeight}
            fill={theme.palette.surface.dimmed}
          />

          <Axis
            withLines
            domain={xScale.domain()}
            range={xScale.range()}
            boundedHeight={dimensions.boundedHeight}
            boundedWidth={dimensions.boundedWidth}
          />

          <g transform={`translate(0, 0)`}>
            {transitions((style, entry) => {
              const idx = sortedEntries.findIndex(e => e.id === entry.id);
              const transformY = idx * ENTRY_HEIGHT;

              const sx = xScale(new Date(entry.stats!.firstStepAt!));
              const height = ENTRY_HEIGHT;

              const width = Math.max(
                xScale(new Date(entry.stats!.lastStepAt!)) -
                  xScale(new Date(entry.stats!.firstStepAt!)),
                10
              );

              return (
                // @ts-ignore
                <a.g style={style} key={`entry--${entry.id}-${idx}`} className="journey">
                  <JourneysTimelineEntry
                    x={sx}
                    width={width}
                    boundedWidth={dimensions.boundedWidth}
                    onMouseLeave={handleOnMouseLeaveEntry}
                    onMouseMove={e => handleOnMouseMoveEntry(e, entry, sx, transformY || 0, idx)}
                    entry={entry}
                    height={height}
                    transformY={transformY || 0}
                  />
                </a.g>
              );
            })}
          </g>
          <CurrentDateMarker
            domain={xScale.domain()}
            range={xScale.range()}
            boundedHeight={dimensions.boundedHeight}
          />

          <FocusLine
            date={focusLine.currentDate}
            y1={0}
            y2={dimensions.boundedHeight}
            x1={focusLine.xPosition}
            x2={focusLine.xPosition}
            opacity={focusLine.visible ? 1 : 0}
          />
        </g>
      </svg>
      <JourneysTimelineTooltip {...tooltip} />
    </div>
  );
};

export const JourneysTimelineLoader = () => (
  <Stack vertical gap="xLarge">
    <Stack justifyContent="space-between">
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
    </Stack>
    <Stack vertical gap="small">
      <div style={{ transform: "translateX(10%)" }}>
        <SkeletonLoader width={512} height={ENTRY_HEIGHT} />
      </div>
      <div style={{ transform: "translateX(27%)" }}>
        <SkeletonLoader width={420} height={ENTRY_HEIGHT} />
      </div>
      <div style={{ transform: "translateX(19%)" }}>
        <SkeletonLoader width={640} height={ENTRY_HEIGHT} />
      </div>
      <div style={{ transform: "translateX(60%)" }}>
        <SkeletonLoader width={420} height={ENTRY_HEIGHT} />
      </div>
      <div style={{ transform: "translateX(77%)" }}>
        <SkeletonLoader width={256} height={ENTRY_HEIGHT} />
      </div>
      <div style={{ transform: "translateX(10%)" }}>
        <SkeletonLoader width={256} height={ENTRY_HEIGHT} />
      </div>
      <div style={{ transform: "translateX(15%)" }}>
        <SkeletonLoader width={512} height={ENTRY_HEIGHT} />
      </div>
    </Stack>
    <Stack justifyContent="space-between">
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
      <SkeletonLoader width={92} />
    </Stack>
  </Stack>
);
