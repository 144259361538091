import { Icon, OptionList, useTheme } from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { EmployeeField, useEmployeeFields } from "../hooks/useEmployeeFields";
import { SelectorIcon } from "./EmployeeFieldSelector";

type EmployeeFieldSelectListProps = {
  onSelect: (path: string, title: string) => void;
  onAdd?: (search: string) => void;
  onBack: () => unknown;
  fieldTypes?: string[];
  disableLinkedEmployee?: boolean;
  title?: string;
};

export const EmployeeFieldSelectList = ({
  fieldTypes,
  onSelect,
  onAdd,
  onBack,
  disableLinkedEmployee,
  title = ""
}: EmployeeFieldSelectListProps) => {
  const { theme } = useTheme();

  const { fields } = useEmployeeFields();

  const [parentField, setParentField] = useState<EmployeeField | null>(null);

  const options = useMemo(() => {
    if (!fields) return [];

    const allowed = fieldTypes ? fields.filter(f => fieldTypes.includes(f.type)) : fields;

    const available =
      !parentField && !disableLinkedEmployee ? allowed : allowed.filter(f => f.type !== "employee");

    return available.map(f => ({
      key: f.key,
      title: f.title,
      startAdornment:
        f.type === "employee" ? <SelectorIcon icon="person" /> : <SelectorIcon icon="attribute" />,
      onClick: () => {
        if (f.type === "employee") {
          setParentField(f);
        } else {
          const path = parentField ? `${parentField.key}.${f.key}` : f.key;
          const title = parentField ? `${parentField.title} - ${f.title}` : f.title;

          onSelect(path, title);
          setParentField(null);
        }
      }
    }));
  }, [fields, parentField, onSelect, fieldTypes, disableLinkedEmployee]);

  return (
    <OptionList
      actions={[
        {
          key: "back",
          title: parentField ? parentField.title : title,
          startAdornment: <Icon name="arrowLeft" color={theme.palette.primary.default} />,
          onClick: () => {
            if (parentField) setParentField(null);
            else onBack();
          }
        }
      ]}
      searchable
      onEnter={search => {
        if (onAdd) onAdd(search);
      }}
      options={options}
      clearSearchOnOptionsChange
    />
  );
};
