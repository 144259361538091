import { Field, Input, Select, Stack } from "@introist/react-foundation/v2";
import { EmployeeRole, EmployeeRoleType, EmployeeRoleTypes } from "./useRoles";
import { UpsertFormProps } from "../../../foundation";

export const RoleForm = ({ value, onChange }: UpsertFormProps<EmployeeRole>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Name">
        <Input value={value.name} onChange={name => onChange({ ...value, name })} autoFocus />
      </Field>
      <Field title="Type">
        <Select
          style={{ width: "100%" }}
          options={EmployeeRoleTypes}
          value={value.type}
          onSelect={opt => onChange({ ...value, type: opt.key as EmployeeRoleType })}
        />
      </Field>
    </Stack>
  );
};
