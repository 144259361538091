import styled from "styled-components";
import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@introist/react-foundation/v2";
import { Automation } from "../../../hooks/useAutomations";

type Props = {
  entry: Automation;
  color?: string;
  height: number;
  x: number;
  transformY: number;
  width: number;
  onMouseMove: (
    event: MouseEvent<SVGGElement>,
    entry: Automation,
    sx: number,
    transformY: number
  ) => void;
  onMouseLeave: (event: MouseEvent<SVGGElement>) => void;
  boundedWidth: number;
};

const StyledRect = styled.rect<{ $color?: string }>`
  fill: white;
  stroke: ${p => p.$color || `var(--palette-border-default)`};
  :hover {
    cursor: pointer;
  }
`;

const StyledText = styled.text`
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  fill: var(--palette-foreground-subdued);
`;

const EmployeeName = styled(StyledText)`
  font-weight: 500;
  fill: var(--palette-foreground-default);
`;

const GAP = 4;
const ICON_SIZE = 14;

const getLabelPosition = (sx: number, width: number, boundedWidth: number, hasIcon: boolean) => {
  const estimatedLabelWidth = 100;

  const doesOverflowRight = sx + width + estimatedLabelWidth + GAP > boundedWidth;
  const doesOverflowLeft = sx - estimatedLabelWidth - GAP < 0;

  let labelX, textAnchor, iconX;
  if (doesOverflowRight && !doesOverflowLeft) {
    if (hasIcon) {
      labelX = sx - ICON_SIZE - GAP * 2;
    } else {
      labelX = sx - GAP;
    }
    iconX = sx - GAP - ICON_SIZE;
    textAnchor = "end";
  } else {
    if (hasIcon) {
      labelX = sx + width + ICON_SIZE + GAP * 2;
    } else {
      labelX = sx + width + GAP;
    }
    iconX = sx + width + GAP;
    textAnchor = "start";
  }

  return { labelX, textAnchor, iconX };
};

export const JourneysTimelineEntry = ({
  entry,
  color,
  height,
  x,
  width,
  transformY,
  boundedWidth,
  onMouseMove,
  onMouseLeave
}: Props) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { labelX, textAnchor, iconX } = getLabelPosition(
    x,
    width,
    boundedWidth,
    entry.status === "failing"
  );

  const truncateText = (text: string, maxLength = 30) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength - 3)}...`;
    }
    return text;
  };

  return (
    <g
      className="journey"
      onMouseMove={e => onMouseMove(e, entry, x, transformY || 0)}
      onMouseLeave={onMouseLeave}
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (!entry.employeeId) return;
        navigate(`/automations/${entry.id}`);
      }}
    >
      <StyledRect
        width={width}
        height={height}
        x={x}
        $color={color}
        strokeWidth={1}
        rx={4}
        ry={4}
      />
      {entry.status === "failing" && (
        <svg
          x={iconX}
          y={height / 2 - 7}
          fill={theme.palette.danger.default}
          width="12"
          height="12"
          viewBox="0 0 12 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6548 5.71137L6.28887 1.34547C6.12933 1.18593 5.87067 1.18593 5.71113 1.34547L1.34522 5.71138C1.18568 5.87091 1.18568 6.12958 1.34522 6.28911L5.71113 10.655C5.87067 10.8146 6.12933 10.8146 6.28887 10.655L10.6548 6.28911C10.8143 6.12958 10.8143 5.87091 10.6548 5.71137ZM7.15548 0.478858C6.51733 -0.159294 5.48268 -0.159294 4.84452 0.478859L0.478614 4.84477C-0.159538 5.48292 -0.159538 6.51757 0.478615 7.15572L4.84452 11.5216C5.48268 12.1598 6.51733 12.1598 7.15548 11.5216L11.5214 7.15572C12.1595 6.51757 12.1595 5.48292 11.5214 4.84477L7.15548 0.478858ZM5.99985 2.7959C6.33828 2.7959 6.61264 3.07025 6.61264 3.40868V6.26834C6.61264 6.60677 6.33828 6.88113 5.99985 6.88113C5.66142 6.88113 5.38707 6.60677 5.38707 6.26834V3.40868C5.38707 3.07025 5.66142 2.7959 5.99985 2.7959ZM6.61264 8.31089C6.61264 7.97245 6.33828 7.6981 5.99985 7.6981C5.66142 7.6981 5.38707 7.97245 5.38707 8.31089V8.47429C5.38707 8.81273 5.66142 9.08708 5.99985 9.08708C6.33828 9.08708 6.61264 8.81273 6.61264 8.47429V8.31089Z"
            fill="inherit"
          />
        </svg>
      )}

      <StyledText
        x={labelX}
        y={height / 2}
        style={{
          dominantBaseline: "text-after-edge",
          textAnchor: textAnchor as "start" | "end"
        }}
      >
        {truncateText(entry.title)}
      </StyledText>
      <EmployeeName
        x={labelX}
        y={height / 2}
        style={{
          dominantBaseline: "text-before-edge",
          fontWeight: "500",
          textAnchor: textAnchor as "start" | "end"
        }}
      >
        {entry.employeeName}
      </EmployeeName>
    </g>
  );
};
