import { useEffect, useState } from "react";
import { getJson } from "../../../services/api/ApiBase";
import { get } from "lodash";

export type WebhookAction = {
  source: string;
  action: string;
  title: string;
};

export const WebhookActionNames: { [k: string]: any } = {
  candidateHired: "Candidate hired"
};

export const useWebhooks = () => {
  const [actions, setActions] = useState<WebhookAction[]>([]);

  useEffect(() => {
    getJson("/ds/webhooks/actions")
      .then(actions =>
        (actions as WebhookAction[]).map(a => ({
          ...a,
          title: get(WebhookActionNames, a.action, a.action)
        }))
      )
      .then(actions => setActions(actions as WebhookAction[]));
  }, []);

  return { actions };
};
