import React, { useState } from "react";

import { ReactComponent as GifIcon } from "./assets/GifIcon.svg";
import { Card, IconButton, Input, Popover, Stack, useTheme } from "@introist/react-foundation/v2";
import ReactGiphySearchbox from "react-giphy-searchbox";
import { config } from "../../../../../Config";

export const GifSelector = ({ onInsert }: { onInsert: (item: any) => void }) => {
  const { theme } = useTheme();

  const [giphyRef, setGiphyRef] = useState<HTMLElement | null>(null);
  const [giphyOpen, setGiphyOpen] = useState(false);

  return (
    <>
      <Stack ref={setGiphyRef}>
        <IconButton
          onClick={() => setGiphyOpen(!giphyOpen)}
          icon={<GifIcon />}
          style={{ width: "30px", height: "30px" }}
        />
      </Stack>

      <Popover
        referenceElement={giphyRef}
        open={giphyOpen}
        onClose={() => setGiphyOpen(false)}
        closeOnContentClick={false}
      >
        <Card style={{ padding: 0 }} elevated>
          <ReactGiphySearchbox
            apiKey={config.giphyApiKey}
            wrapperClassName="giphy-select"
            onSelect={(item: any) => {
              onInsert(item.images.fixed_width.url);
              setGiphyOpen(false);
            }}
          />
          <Input
            type="text"
            size="small"
            placeholder="Add custom gif by url"
            style={{ margin: theme.spacing.small }}
            onKeyUp={event => {
              if (event.key === "Enter") {
                onInsert(event.currentTarget.value);
                setGiphyOpen(false);
              }
            }}
          />
        </Card>
      </Popover>
    </>
  );
};
