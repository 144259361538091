import { api } from "services/rpc/RpcProvider";
import { FileBase } from "./FileBase";

type AttachmentProps = {
  id: string;
  name?: string;
  readOnly?: boolean;
  onRemove?: (fileId: string) => unknown;
};

export const AttachmentItem = ({ id, name, readOnly, onRemove }: AttachmentProps) => {
  const { mutateAsync: getDownloadUrl } = api.files.getDownloadUrl.useMutation({});

  const { data: file } = api.files.find.useQuery({ fileId: id });
  if (!file) {
    return (
      <FileBase
        title={name ?? "Unknown attachment"}
        onRemove={!readOnly && onRemove ? () => onRemove(id) : undefined}
      />
    );
  }

  const onOpen = async () => {
    const url = await getDownloadUrl({ fileId: id });
    window.open(url);
  };

  return (
    <FileBase
      title={file.name}
      onRemove={!readOnly && onRemove ? () => onRemove(file.id) : undefined}
      onClick={onOpen}
    />
  );
};
