import { ListView, TopBarRoute } from "../../../components/templates";
import { useRoleActions, useRoles } from "./useRoles";
import { UpsertModal, useSearch } from "../../../foundation";
import { IconPickerIcon, ListRow } from "../../../components/organisms";
import { Stack, Title } from "@introist/react-foundation/v2";

import { useState } from "react";
import { RoleForm } from "./RoleForm";
import { useNavigate, useParams } from "react-router";
import { RoleEditorDrawer } from "./RoleEditorDrawer";

export const RolesRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { roles } = useRoles();
  const { create } = useRoleActions();

  const { filtered, search, setSearch } = useSearch(roles, { fields: ["name"] });

  const [createOpen, setCreateOpen] = useState(false);

  return (
    <TopBarRoute paths={[{ key: "roles", title: "Roles" }]}>
      <Stack vertical gap="xLarge">
        <ListView.Header
          title="Roles"
          count={filtered?.length}
          search={{ search, setSearch }}
          onCreate={() => setCreateOpen(true)}
        />
        <ListView.List
          data={filtered}
          renderRow={role => (
            <ListRow
              gridTemplateColumns="30px 1fr"
              onClick={() => navigate(`/data/roles/${role.id}`)}
            >
              <IconPickerIcon icon={role.icon} fallbackIcon={role.icon} />
              <Title variant="bold">{role.name}</Title>
            </ListRow>
          )}
        />
        <RoleEditorDrawer onClose={() => navigate("/data/roles")} id={id} />

        <UpsertModal
          open={createOpen}
          onClose={() => setCreateOpen(false)}
          onCreate={async entry => {
            await create(entry).then(created => navigate(`/data/roles/${created!.id}`));
          }}
          onUpdate={() => {
            throw new Error("Not implemented");
          }}
          template={{
            type: "employee"
          }}
          Form={RoleForm}
          entryName="role"
        />
      </Stack>
    </TopBarRoute>
  );
};
