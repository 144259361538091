import { EmailAddressCard } from "./EmailAddressCard";
import { EmployeeFieldSelector } from "../../../employees/fields/EmployeeFieldSelector";
import { IconName, Stack } from "@introist/react-foundation/v2";
import { useTags } from "../../../employees/hooks/useTags";

export const EmailAddressList = ({
  value,
  onChange,
  placeholder = "Add receiver",
  single,
  icon = "plus"
}: {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  single?: boolean;
  icon?: IconName;
}) => {
  const { tagTitle } = useTags();

  return (
    <Stack vertical gap="xSmall">
      {value.map((path: string) => (
        <EmailAddressCard
          value={tagTitle(path)}
          onRemove={() => onChange(value.filter((p: string) => p !== path))}
        />
      ))}
      {(!single || value.length === 0) && (
        <div>
          <EmployeeFieldSelector
            fieldTypes={["email"]}
            onSelect={path => onChange([...value, path])}
            onAdd={path => onChange([...value, path])}
            startIcon={icon}
            variant="blended"
            style={value.length > 0 ? { marginTop: "var(--spacing-medium)" } : undefined}
          >
            {placeholder}
          </EmployeeFieldSelector>
        </div>
      )}
    </Stack>
  );
};
