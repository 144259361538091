import { useCallback } from "react";
import { useEmployeeFields } from "./useEmployeeFields";
import { useRoles } from "../roles/useRoles";

export const useTags = () => {
  const { fieldTitle } = useEmployeeFields();
  const { roles } = useRoles();

  const tagTitle = useCallback(
    (path: string) => {
      if (path.startsWith("roles")) {
        //eslint-disable-next-line
        const [_, roleId, field] = path.split(".");
        const role = roles.find(role => role.id === roleId);

        if (role?.type === "email") return role.name;
        if (role?.type === "employee") return `${role.name} - ${fieldTitle(field)}`;

        return "Unknown role";
      }

      return fieldTitle(path);
    },
    [fieldTitle, roles]
  );

  return { tagTitle };
};
