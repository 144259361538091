import "./HtmlEditor.scss";

import { Stack } from "@introist/react-foundation/v2";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { FontSize } from "./Extensions/FontSize";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import styled from "styled-components";

import { Emoji } from "./Extensions/Emoji";
import { Field } from "./Extensions/Field";
import { Image } from "./Extensions/Image";
import { Toolbar } from "./Toolbar/Toolbar";
import { BackgroundColor } from "./Extensions/BackgroundColor";

const Box = styled(Stack)<{ error?: boolean }>`
  padding: var(--spacing-small) var(--spacing-medium);
  background: ${props =>
    props.error ? "var(--palette-danger-ghosted)" : "var(--palette-surface-subdued)"};
  border-radius: var(--rounding-small);
`;

const EditorWrapper = styled(Box)`
  max-width: 458px;
  box-sizing: border-box;
  > div {
    flex-grow: 1;
    width: 100%;
  }
`;

const ConfiguredLink = Link.configure({
  openOnClick: false,
  protocols: ["https", "http"],
  autolink: true,
  linkOnPaste: true,
  defaultProtocol: "https"
});

interface HtmlEditorProps {
  content: string;
  onChange?: (value: string) => unknown;
  placeholder?: string;
  error?: boolean;
  underline?: boolean;
  fields?: boolean;
  emojis?: boolean;
  images?: boolean;
  gifs?: boolean;
  readonly?: boolean;
}

export const HtmlEditor = ({
  content,
  onChange,
  placeholder,
  underline = true,
  fields = false,
  emojis = true,
  images = true,
  gifs = true,
  error,
  readonly = false
}: HtmlEditorProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextStyle,
      FontSize,
      Emoji,
      Field,
      Underline,
      ConfiguredLink,
      Image,
      Placeholder.configure({ placeholder }),
      Color,
      TextAlign.configure({ types: ["paragraph"], alignments: ["left", "right", "center"] }),
      BackgroundColor
    ],
    content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChange && onChange(html);
    },
    editable: !readonly
  });

  const toolbarConfigurations = { underline, emojis, images, gifs, fields };

  return (
    <>
      <Stack vertical gap="xSmall">
        {!readonly && (
          <Box gap="xSmall">
            <Toolbar editor={editor} toolbarConfigurations={toolbarConfigurations} />
          </Box>
        )}
        <EditorWrapper justifyContent="flex-start" alignItems="flex-start" error={error}>
          <EditorContent editor={editor} />
        </EditorWrapper>
      </Stack>
    </>
  );
};
