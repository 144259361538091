import { useMemo } from "react";
import { CompanyEventSetting } from "services/rpc/RpcProvider";

const EVENT_KEY_CATEGORY_MAP = {
  Presenters: "Presenter settings",
  CancelOnPresenterDecline: "Presenter settings",
  RescheduleAttendeeOnDecline: "Attendee settings",
  RescheduleAttendeeOnWaiting: "Attendee settings",
  RemindAttendeeOnWaiting: "Attendee settings",
  VerifyAttendance: "Attendee settings",
  MaxAttendees: "Session settings",
  RescheduleOnWeekendOrHoliday: "Session settings",
  BookMeetingRoom: "Session settings",
  MoveToWorkday: "Session settings"
};

export const useGroupedCompanyCompanyEventSettings = (settings: CompanyEventSetting[]) => {
  const groupedSettings = useMemo(() => {
    return settings.reduce((acc, setting: CompanyEventSetting) => {
      const category = EVENT_KEY_CATEGORY_MAP[setting.setting] || "Unknown";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(setting);
      return acc;
    }, {} as Record<string, CompanyEventSetting[]>);
  }, [settings]);

  return groupedSettings;
};
